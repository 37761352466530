// npm
import React, { memo, useRef, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  Box,
  Stack,
  SxProps,
  alpha,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import SwiperCore, { Navigation, Thumbs } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

// constants
import { BreakpointProps } from '@constants/breakpointProps'

// components
import NavButton from '@atoms/buttons/NavButton'
import Lightbox from '@molecules/lightboxes/Lightbox'

// images
import ChevronRight from '@images/icons/chevron-right.svg'

interface Props extends BreakpointProps {
  artworkQueryData:
    | Queries.ArtworkDetailKnownArtistQuery
    | Queries.ArtworkDetailUnknownArtistQuery
  spaceX: SxProps
}

const ThumbSwiperSlide = styled(SwiperSlide, {
  shouldForwardProp: (prop) => prop !== 'breakpoint',
})<BreakpointProps>(({ breakpoint, theme }) => ({
  marginBottom: '5px',
  boxSizing: 'border-box',
  padding: '5px',
  border: `1px solid ${theme.palette.common.white}`,
  cursor: 'pointer',
  backgroundColor: theme.palette.common.white,
  ['&:hover']: {
    borderColor: alpha(theme.palette.primary.main, 0.5),
  },
  ['&.swiper-slide-thumb-active']: {
    borderColor: theme.palette.primary.main,
  },

  width: 'auto !important',
  marginRight: theme.spacing(1),
  [theme.breakpoints.up(breakpoint)]: {
    width: '100% !important',
    height: 'auto !important',
    marginRight: 0,
  },
}))

const ImageSwiperSlide = styled(SwiperSlide)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'auto !important',
}))

const StyledGatsbyImage = styled(GatsbyImage)(({ theme }) => ({
  cursor: 'pointer',
}))

const StyledNavButton = styled(NavButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
}))

const ArtworkDetailSwiper = ({
  artworkQueryData,
  spaceX,
  breakpoint,
}: Props) => {
  const [lightboxOpen, setLightboxOpen] = useState(false)
  const [lightboxIndex, setLightboxIndex] = useState(0)

  const artwork = artworkQueryData.datoCmsArtwork
  const theme = useTheme()
  const navDisplayUp = useMediaQuery('@media (min-width:1352px)') // custom breakpoint
  const breakpointUp = useMediaQuery(theme.breakpoints.up(breakpoint))

  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  SwiperCore.use([Navigation, Thumbs])

  const resetLightboxState = () => {
    setLightboxOpen(false)
    setLightboxIndex(0)
  }

  return (
    <>
      <Box
        position="relative"
        height={breakpointUp ? '100%' : 'auto'}
        display="flex"
        alignItems="stretch"
        pb={{ xs: '136px', [breakpoint]: 0 }}
      >
        <Box
          position="absolute"
          sx={{
            top: { xs: 'auto', [breakpoint]: 0 },
            bottom: { xs: 0, [breakpoint]: 'auto' },
            left: { xs: '50%', [breakpoint]: spaceX.xl },
            transform: {
              xs: 'translateX(-50%)',
              [breakpoint]: 'none',
            },
            zIndex: 10,
          }}
        >
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={0}
            direction={breakpointUp ? 'vertical' : 'horizontal'}
            slidesPerView={2}
            watchSlidesProgress={true}
            modules={[Thumbs]}
            className="thumbs"
          >
            {artwork.framedImage &&
              artwork.framedThumb &&
              artwork.framedThumbMatchHeight && (
                <ThumbSwiperSlide breakpoint={breakpoint}>
                  <GatsbyImage
                    image={
                      breakpointUp
                        ? artwork.framedThumb.gatsbyImageData
                        : artwork.framedThumbMatchHeight.gatsbyImageData
                    }
                    alt="Artwork with frame"
                    loading="eager"
                  />
                </ThumbSwiperSlide>
              )}
            {artwork.unframedImage &&
              artwork.unframedThumb &&
              artwork.unframedThumbMatchHeight && (
                <ThumbSwiperSlide breakpoint={breakpoint}>
                  <GatsbyImage
                    image={
                      breakpointUp
                        ? artwork.unframedThumb.gatsbyImageData
                        : artwork.unframedThumbMatchHeight.gatsbyImageData
                    }
                    alt="Artwork without frame"
                    loading="eager"
                  />
                </ThumbSwiperSlide>
              )}
          </Swiper>
        </Box>

        <Stack
          direction="row"
          justifyContent={'center'}
          alignItems={{ xs: 'center', xl: 'flex-start' }}
          sx={{ width: '100%', height: '100%', position: 'relative' }}
        >
          <Swiper
            onInit={(swiper) => {
              // @ts-ignore
              swiper.params.navigation.prevEl = prevRef.current
              // @ts-ignore
              swiper.params.navigation.nextEl = nextRef.current
              swiper.navigation.init()
              swiper.navigation.update()
            }}
            spaceBetween={0}
            slidesPerView={1}
            observer
            thumbs={{ swiper: thumbsSwiper }}
            modules={[Navigation, Thumbs]}
            className="main"
          >
            {artwork.framedImage && (
              <ImageSwiperSlide>
                <Box
                  px={spaceX}
                  onClick={() => {
                    setLightboxIndex(0)
                    setLightboxOpen(true)
                  }}
                >
                  <StyledGatsbyImage
                    image={artwork.framedImage.gatsbyImageData}
                    alt="Artwork with frame"
                  />
                </Box>
              </ImageSwiperSlide>
            )}
            {artwork.unframedImage && (
              <ImageSwiperSlide>
                <Box
                  px={spaceX}
                  onClick={() => {
                    setLightboxIndex(1)
                    setLightboxOpen(true)
                  }}
                >
                  <StyledGatsbyImage
                    image={artwork.unframedImage.gatsbyImageData}
                    alt="Artwork without frame"
                  />
                </Box>
              </ImageSwiperSlide>
            )}
            {navDisplayUp && (
              <>
                <StyledNavButton
                  aria-label="previous"
                  themeVariant="light"
                  ref={prevRef}
                  sx={{
                    left: spaceX,
                    svg: {
                      transform: 'rotate(180deg)',
                    },
                  }}
                >
                  <ChevronRight />
                </StyledNavButton>
                <StyledNavButton
                  aria-label="next"
                  themeVariant="light"
                  ref={nextRef}
                  sx={{ right: spaceX }}
                >
                  <ChevronRight />
                </StyledNavButton>
              </>
            )}
          </Swiper>
        </Stack>
      </Box>
      <Lightbox
        open={lightboxOpen}
        index={lightboxIndex}
        resetParentState={resetLightboxState}
        slides={[
          {
            src: artwork.framedFull.url,
          },
          {
            src: artwork.unframedFull.url,
          },
        ]}
      />
    </>
  )
}

export default memo(ArtworkDetailSwiper)
